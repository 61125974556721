function showOpenFilePickerPolyfill(options: OpenFilePickerOptions): Promise<any> {
    return new Promise((resolve) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = options.multiple;
        input.accept = options.types
            .map((type) => type.accept)
            .flatMap((inst) => Object.keys(inst).flatMap((key) => inst[key]))
            .join(',');

        // See https://stackoverflow.com/questions/47664777/javascript-file-input-onchange-not-working-ios-safari-only
        input.style.position = 'fixed';
        input.style.top = '-100000px';
        input.style.left = '-100000px';
        document.body.appendChild(input);

        input.addEventListener('change', () => {
            resolve(
                [...input.files].map((file) => {
                    return {
                        getFile: async () =>
                            new Promise((resolve) => {
                                resolve(file);
                                document.body.removeChild(input);
                            }),
                    };
                })
            );
        });

        input.click();
    });
}

if (typeof window.showOpenFilePicker !== 'function') {
    window.showOpenFilePicker = showOpenFilePickerPolyfill
}
